<template>
  <div class="row">

    <div class="col px-0 px-lg-3">
      <div class="shadow rounded bg-white py-3 py-md-4 px-lg-2 mb-4">

        <div class="col d-flex flex-wrap align-items-center mb-3">
          <span class="h5 mr-3"><i class="nav-icon icons8-city-landscape"></i> {{ title }}</span>
          <router-link to="/cities/add" class="btn btn-success">Добавить</router-link>
        </div>

        <div class="col">
          <v-server-table :columns="columns" :options="tableOptions" :name="'city-list'">
            <router-link :to="cityEditLink( props.row.id )"
                         slot="action"
                         slot-scope="props"
                         class="btn btn-default py-0">
              <i class="icons8-edit"></i>
            </router-link>
          </v-server-table>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'CityList',

    metaInfo() {
      return {
        title: this.title,
      }
    },

    data() {
      return {
        title: 'Города',
        columnsSettings:
          [
            {
              name: 'Город',
              alias: 'name',
              sortable: true,
              filterable: true,
              visible: true,
            },
            {
              name: '',
              alias: 'action',
              sortable: false,
              filterable: false,
              visible: true,
            },
          ],
      }
    },

    computed: {
      tableOptions() {
        return {
          saveState: true,
          filterByColumn: true,
          skin: 'table table-bordered table-striped',
          filterable: this.filterableColumns,
          sortable: this.sortableColumns,
          headings: this.headings,
          perPage: 10,
          perPageValues: [10],
          pagination: { chunk: this.$store.getters.windowSize === 'large' ? 10 : 3 },
          columnsDropdown: false,
          columnsClasses: {
            id: 'mw-100',
          },
          sortIcon: {
            base: 'icons8',
            up: 'icons8-chevron-up',
            down: 'icons8-chevron-down',
            is: 'icons8-up-and-down-arrows',
          },

          responseAdapter( resp ) {
            let data = this.getResponseData( resp )
            let cities = data.data.data

            return {
              data: cities,
              count: data.data.meta ? data.data.meta.pagination.total : null,
            }
          },

          requestFunction( params ) {
            let options = {
              params: params,
              paginate: 1,
            }

            return this.$api.city.findAllCities( options )
          },
        }
      },

      filterableColumns() {
        return this.columnsSettings.filter( column => column.filterable ).map( column => column.alias )
      },

      sortableColumns() {
        return this.columnsSettings.filter( column => column.sortable ).map( column => column.alias )
      },

      headings() {
        let result = {}

        this.columnsSettings.forEach( column => {
          result[column.alias] = column.name
        } )

        return result
      },

      columns() {
        return this.columnsSettings.filter( column => column.visible ).map( column => column.alias )
      },
    },

    methods: {
      cityEditLink( id ) {
        return `/cities/${id}/edit`
      },
    },
  }
</script>
